<template>
  <div class="m-parent pa-16 d-flex flex-column">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="w-full h-full d-flex flex-column m-holder">
      <!--TOP-->
      <v-row class="space-between top-m">
        <v-col cols="2" style="max-width: 100%; max-height: 100%">
          <img src="../../assets/logo_violet.png" alt="" class="logo">
        </v-col>
        <v-col class="text-end">
          <div class="h-link d-flex text-p">
            <a href="https://www.sofa-collaboration.com/" class="text-decoration-none">{{
                $t('meeting.link.sofa_link')
                                                                                       }}</a>
            <v-icon color="primary" class="h-link-icon outline">mdi-chevron-right-circle-outline</v-icon>
            <v-icon color="primary" class="h-link-icon full">mdi-chevron-right-circle</v-icon>
          </div>
        </v-col>
      </v-row>

      <!-- CONTENT IF MEETING NOT FOUND     -->
      <v-row style="align-content: center" class="mr-0 justify-center" v-if="!meeting_found">
        <v-col cols="12">
          <div class="text-p l-invalid text-center">
            {{ $t('meeting.meeting_not_found') }}
          </div>
        </v-col>
      </v-row>

      <!-- CONTENT IF MEETING EXPIRED     -->
      <v-row style="align-content: center" class="mr-0 justify-center" v-else-if="meeting_past">
        <v-col cols="12">
          <div class="text-p l-invalid text-center">
            {{ $t('meeting.meeting_ended') }} <br> <strong> {{ format_meeting_end }}</strong>
          </div>
        </v-col>
      </v-row>

      <!--CONTENT IF MEETING FOUND-->
      <v-row style=" align-content: center" class="align-center" v-else>
        <!--LEFT-->
        <v-col cols="12" lg="4" md="8" xs="12">
          <div class="t text-uppercase mb-O">
            {{ $t('meeting.meeting_found.title') }}
          </div>
          <div class="t-content pt-10 pb-10 pr-12 ">
            <strong>{{ meeting.creator }} </strong> {{ $t('meeting.meeting_found.content.invite') }} <strong>
            {{ meeting.room }} </strong>
                                                    {{ $t('meeting.meeting_found.content.to_participate') }}
                                                    « <strong>{{ meeting.comment }}</strong> » du
            <strong> {{ format_meeting_start }} </strong> {{ $t('meeting.meeting_found.content.to') }}
            <strong> {{ format_meeting_end }} </strong> {{
              $t('meeting.meeting_found.content.duration')
                                                    }} <strong>{{ time_between_dates }}</strong>.
          </div>
          <!--  ACTION BUTTON COPY LINK IF WIN        -->
          <div class="t-action">
            <v-btn outlined class="primary" v-if="!isMac" color="white" :href="protocol_link">
              <a :href="protocol_link"></a>
              {{ $t('meeting.meeting_found.button.enter_room') }}
            </v-btn>
          </div>
          <!-- END ACTION BUTTON COPY LINK IF WIN        -->


          <!--  ACTION BUTTON COPY LINK IF MAC        -->
          <div class="t-action" v-if="isMac">
            <v-btn outlined class="primary" color="white" @click="copyLink">
              {{ $t('meeting.meeting_found.button.copy_link') }}
            </v-btn>
            <!-- HIDDEN INPUT FOR MEETING LINK     -->
            <input
                v-on:focus="$event.target.select()"
                ref="meeting_link"
                readonly
                hidden
                :value="copy_meeting_link" />
            <!--END HIDDEN INPUT FOR MEETING LINK     -->
          </div>
          <!-- END ACTION BUTTON COPY LINK IF MAC        -->
        </v-col>

        <!--MIDDLE-->
        <v-col cols="5" class="hidden-md-and-down">
          <img src="../../assets/computer.png" class="image-middle">
        </v-col>

        <!--RIGHT-->
        <v-col cols="12" lg="3" md="4" xs="12" class="a-parent">
          <div class="font-weight-bold a-title">
            {{ $t('meeting.meeting_found.no_sofa.title') }}
          </div>
          <div class="a-subtitle" style="font-size: 0.8rem">
            {{ $t('meeting.meeting_found.no_sofa.download') }}
          </div>
          <div class="a-app">
            <!--DOWNLOAD MAC BINARY-->
            <div class="d-flex b-app py-6" v-if="download_mac">
              <a :href="download_mac" target="_blank" download>
              <v-btn class="b-btn"
                     width="68px"
                     height="68px"
                     color="primary"
                     dark
                     outlined>
                <v-icon class="mdi-36px b-icon" color="white">mdi-apple</v-icon>
              </v-btn>
              </a>
              <div class="pl-5 b-text"> {{ $t('meeting.meeting_found.no_sofa.platforms.mac') }}</div>
            </div>
            <!--END DOWNLOAD MAC BINARY-->

            <!--DOWNLOAD WINDOWS BINARY-->
            <div class="d-flex b-app pb-6" v-if="download_win">
              <a  :href="download_win" target="_blank" :download="windows_dwl_filename">
                <v-btn class="b-btn"
                       width="68px"
                       height="68px"
                       color="primary"
                       dark
                       outlined>
                  <v-icon class="mdi-36px b-icon" color="white">mdi-microsoft</v-icon>
                </v-btn>
              </a>
              <div class="pl-5 b-text"> {{ $t('meeting.meeting_found.no_sofa.platforms.win') }}</div>
            </div>
            <!--END DOWNLOAD WINDOWS BINARY-->

            <!--DOWNLOAD ANDOIRD BINARY-->
            <div class="d-flex b-app" v-if="download_android">
              <a :href="download_android" target="_blank" download>
              <v-btn class="b-btn"
                     width="68px"
                     height="68px"
                     color="primary"
                     dark
                     outlined>
                <v-icon class="mdi-36px b-icon" color="white">mdi-android</v-icon>
              </v-btn>
              </a>
              <div class="pl-5 b-text">{{ $t('meeting.meeting_found.no_sofa.platforms.android') }}</div>
            </div>
            <!--END DOWNLOAD ANDROID BINARY-->

          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import helpers from "../../plugins/helpers";

export default {
  name: "Home.vue",
  data: () => ({
        meeting: {},
        meeting_found: false,
        meeting_past: false,

        format_meeting_start: null,
        format_meeting_end: null,
        time_between_dates: null,

        loading: false,
        date_format_options: {
          year: "numeric",
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
          timeZone: 'UTC'
        },
        isMac: false,
        copy_meeting_link: '',
        protocol_link: '', // protocol link that opens the app and copies the meeting link automatically, works only for Windows
        download_mac: null,
        download_win: null,
        download_android: null,
        windows_dwl_filename:null
      }
  ),
  methods: {
    /**
     * Fetch meeting link details
     * @return {Promise<void>}
     */
    async fetchMeetingLink() {
      try {
        this.loading = true

        const response = (await this.axios.get('/meeting/info/' + this.$route.query.link)).data
        this.protocol_link = response.protocol
        this.download_mac = response.mac_binary_url
        this.download_win = response.win_binary_url
        this.download_android = response.android_binary_url

        this.windows_dwl_filename = response.win_download_client_name

        this.meeting = response.meeting

        if (!this._.isEmpty(this.meeting))
          this.meeting_found = true

        if (this.meeting_found) {

          let date_begin = new Date(this.meeting.begin)
          let date_end = new Date(this.meeting.end)

          this.format_meeting_start = date_begin.toLocaleString('fr-fr', this.date_format_options).replace(',', ' à ');
          this.format_meeting_end = date_end.toLocaleString('fr-fr', this.date_format_options).replace(',', ' à ');

          // Check if meeting ended
          if (date_end.getTime() < Date.now())
            this.meeting_past = true

          else {
            // Return string w meeting duration in hours, minutes, weeks etc btw meeting start and end
            let difference_dates = helpers.returnTimeDifference(date_end, date_begin)
            this.time_between_dates = helpers.buildStringTimeDifference(difference_dates.minute, difference_dates.hour, difference_dates.day, difference_dates.week, difference_dates.month, difference_dates.year)

            //
          }
        }

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              // Meeting does not exist
            case 404:
              this.meeting_found = false
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Unknown error occurred.', '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Cannot open sofa from mac as app protocol not declared,
     *
     * Copy link in user clipboard and enter room meeting by copying meeting link directly in the app
     */
    async copyLink() {
      // focus hidden input
      this.$refs.meeting_link.focus();

      // copy link to clipboard
      document.execCommand('copy');
      await navigator.clipboard.writeText(this.copy_meeting_link);
    }
  },
  async mounted() {
    this.isMac = navigator.platform === 'MacIntel';
    this.copy_meeting_link = process.env.VUE_APP_WEBSITE + this.$route.fullPath

    await this.fetchMeetingLink()
  }
}

</script>

<style lang="scss" scoped>

.v-application a {
  text-decoration: none!important;
}

.m-parent {
  height: 100vh;

  .m-holder {
    width: 100%;
    height: 100%;
  }
}

.top-m {
  max-height: 10vh;
}

.text-p {
  color: #790346;
}

.l-invalid {
  font-size: 2rem;
}

.m-parent::-webkit-scrollbar {
  display: none;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.image-middle {
  max-width: 100%;
}

.t {
  font-size: 49px;
  font-weight: 700;
}

.a-parent {
  padding-left: 70px;
}

.b-app {
  align-items: center;

  .b-btn {
    background-color: #790346;
    border-radius: 10px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

  .b-icon {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

  .b-text {
    font-size: 15px;
    font-weight: 400;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
  }
}

.b-app:hover {
  .b-btn {
    background-color: white;
    border-width: 3px;
  }

  .b-icon::before {
    color: #790346 !important;
  }

  .b-text {
    font-weight: 500;
  }
}


.h-link {
  justify-content: flex-end;
  cursor: pointer;
  font-size: 14px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;

  .h-link-icon {
    padding-left: 10px;
    font-size: 16px;
  }

  .full {
    display: none;
  }
}

.h-link:hover {
  letter-spacing: 1px;
  font-weight: 500;

  .outline {
    display: none;
  }

  .full {
    display: inline;
  }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .m-parent {
    padding: 30px !important;
  }

  .top-m {
    max-height: 20vh;
  }
}

/* Small devices  */

@media only screen and (min-width: 600px) and (max-width: 960px) {

  .m-parent {
    overflow: scroll !important;
    padding: 30px !important;
  }

  .top-m {
    max-height: 20vh;
  }

  .t {
    text-align: center;
  }

  .t-content {
    text-align: center;
    padding-right: 0px;
  }

  .t-action {
    text-align: center;
  }

  .a-parent {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .a-title {
      padding-top: 30px;
      font-size: 23px;
      text-align: center;
    }

    .a-subtitle {
      font-size: 23px;
      text-align: center;
    }
  }
}

/* Medium devices  => image is hidden in md*/
@media only screen and (min-width: 960px) and (max-width: 1264px) {
  .t {
    font-size: 40px;
  }
}

/* Large devices */
@media only screen and (min-width: 1264px) and (max-width: 1904px) {
  .t {
    font-size: 49px;
  }
}

</style>
