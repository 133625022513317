import _ from 'lodash'

import {i18n} from "./i18n";


export default class helpers {

	/**
	 * Check if string is email address
	 *
	 * Returns true if email is valid, false otherwise
	 * @param email
	 * @return {boolean}
	 */
	static checkEmailValid(email) {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			) !== null;
	}

	/**
	 * Returns right part of a split string
	 *
	 *
	 * @param string
	 * @param separator
	 * @return {*}
	 */
	static getSecondPartOfString(string, separator) {
		return string.split(separator)[1];
	}

	static returnTimeDifference(date_future, date_now) {

		let d = Math.abs(date_future - date_now) / 1000;                           // delta
		let r = {};                                                                // result
		let s = {                                                                  // structure
			year: 31536000,
			month: 2592000,
			week: 604800, // uncomment row to ignore
			day: 86400,   // feel free to add your own row
			hour: 3600,
			minute: 60,
			second: 1
		};

		Object.keys(s).forEach(function (key) {
			r[key] = Math.floor(d / s[key]);
			d -= r[key] * s[key];
		});

		return r
	}

	static buildStringTimeDifference(minute, hour, day, week, month, year) {
		const $t = i18n.messages[i18n.locale];

		let temp_array = []

		temp_array.push(
			((year !== 0) ? year + $t.meeting.meeting_found.content.duration_length.year : null),
					((month !== 0) ? month + $t.meeting.meeting_found.content.duration_length.month : null),
					((week !== 0) ? week + $t.meeting.meeting_found.content.duration_length.week : null),
					((day !== 0) ? day + $t.meeting.meeting_found.content.duration_length.day : null),
					((hour !== 0) ? hour + $t.meeting.meeting_found.content.duration_length.hour : null),
					((minute !== 0) ? minute + $t.meeting.meeting_found.content.duration_length.minute : null)

			)
		return _.filter(temp_array).join(', ')


	}
}
